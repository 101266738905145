import React, { useState, useEffect } from "react"
import SEO from "components/seo"
import VideoWrapper from "components/VideoWrapper"
import Heading from "components/Heading"
import Quote from "components/Quote"
import { motion, useAnimation } from "framer-motion"
import Img from "components/Img"
import Interweave from "interweave"

const heroSlideVariants = {
  initial: {
    opacity: 0,
    x: 25,
    transition: {
      duration: 0.35,
      ease: "easeInOut",
    },
  },
  hidden: {
    opacity: 0,
    x: -25,
    transition: {
      duration: 0.35,
      ease: "easeOut",
    },
  },
  visible: {
    opacity: 1,
    x: 0,
    transition: {
      duration: 0.95,
      ease: "easeInOut",
    },
  },
}

const heroTextVariants = {
  hidden: {
    opacity: 0,
    y: 25,
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.325,
      ease: "easeInOut",
      delay: 0.225,
    },
  },
}

const ViewAbout = ({pageData}) => {
  const [imageOneLoaded, setImageOne] = useState(false)
  const [imageTwoLoaded, setImageTwo] = useState(false)
  const [imageThreeLoaded, setImageThree] = useState(false)
  const [imageFourLoaded, setImageFour] = useState(false)

  const imageOneController = useAnimation()
  const imageTwoController = useAnimation()
  const imageThreeController = useAnimation()
  const imageFourController = useAnimation()
  const primaryTextController = useAnimation()

  const sequence = async () => {
    await imageOneController.start("visible")
    await imageOneController.start("hidden")

    await imageTwoController.start("visible")
    await imageTwoController.start("hidden")

    await imageThreeController.start("visible")
    await imageThreeController.start("hidden")

    await imageFourController.start("visible")
    await imageFourController.start("hidden")

    imageOneController.start("visible")
    imageTwoController.start("visible")
    imageThreeController.start("visible")
    imageFourController.start("visible")

    await primaryTextController.start("visible")
  }

  const { page, heading, videoHomepage, aboutBlock, testimonial} = pageData;

  useEffect(() => {
    if (
      imageOneLoaded &&
      imageTwoLoaded &&
      imageThreeLoaded &&
      imageFourLoaded
    ) {
      sequence()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageOneLoaded, imageTwoLoaded, imageThreeLoaded, imageFourLoaded])

  return (
    <React.Fragment>
      <SEO title={page} />
      <div className="max-container real-hero wrapper--about--hero">
        <motion.div className="container hero-container">
          <div className="hero-text">
            <motion.div
              initial={"hidden"}
              animate={primaryTextController}
              variants={heroTextVariants}
              className="hero-text-animation"
            >
              <Heading className="is-heading">{heading}</Heading>
            </motion.div>
          </div>
          <motion.div
            initial={"initial"}
            animate={imageOneController}
            variants={heroSlideVariants}
            className="hero-slide"
          >
            <Img
              className="hero-image-wrapper"
              name="heroAboutImage1"
              isFluid={true}
              imageProps={{
                onLoad: () => setImageOne(true),
              }}
            />
          </motion.div>
          <motion.div
            initial={"initial"}
            animate={imageTwoController}
            variants={heroSlideVariants}
            className="hero-slide"
          >
            <Img
              className="hero-image-wrapper"
              name="heroAboutImage2"
              isFluid={true}
              imageProps={{
                onLoad: () => setImageTwo(true),
              }}
            />
          </motion.div>
          <motion.div
            initial={"initial"}
            animate={imageThreeController}
            variants={heroSlideVariants}
            className="hero-slide"
          >
            <Img
              className="hero-image-wrapper"
              name="heroAboutImage3"
              isFluid={true}
              imageProps={{
                onLoad: () => setImageThree(true),
              }}
            />
          </motion.div>
          <motion.div
            initial={"initial"}
            animate={imageFourController}
            variants={heroSlideVariants}
            className="hero-slide"
          >
            <Img
              className="hero-image-wrapper"
              name="heroAboutImage4"
              isFluid={true}
              imageProps={{
                onLoad: () => setImageFour(true),
              }}
            />
          </motion.div>
        </motion.div>
      </div>

      <div className="max-container wrapper--about--quote max-quote wrapper--about--block-1">
        <div className="container">
          <Quote className="about-quote">
            <Interweave content={testimonial} />
          </Quote>
        </div>
      </div>
      <div className="max-container wrapper--about--block-2">
        <div className="max-1048 container container-with-background right">
          <div className="col-image">
            <div className="image-offset full">
              <Img
                name="aboutBettyShaun"
                isFluid={true}
                alt={aboutBlock.imageCaption}
              />
            </div>
          </div>
          <div className="col-content">
            <div className="content-body">
              <p>
                {aboutBlock.text}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="max-container wrapper--about--block-3">
        <Heading className="align-center">{videoHomepage.title}</Heading>
        <VideoWrapper videoId={videoHomepage.videoId} />
      </div>
    </React.Fragment>
  )
}

export default ViewAbout
