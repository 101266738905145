import React from 'react'
import cx from 'classnames';

const Quote = ({className, children}) => {
  return (
    <div className={cx(`single-quote ${className}`)}>
      {children}
    </div>
  )
}

Quote.defaultProps = {
  className: '',
}

export default Quote